import React from "react";
import { graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MarkDownParser from "../components/md-parser";

const BlogIndex = ({ data, location }) => {
  // console.log("Current PAGE DATA: !", data);
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
     <div>This site is currently down for maintenance.</div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/pages/index/" } }) {
      edges {
        node {
          id
          frontmatter {
            page
            title
          }
          html
          fileAbsolutePath
          fields {
            slug
          }
        }
      }
    }
  }
`;
